<template>
  <div class="login-box">
    <div class="login">
      <div class="header">
        <div class="zh">南京市鼓楼医院智慧排水管理系统</div>
        <div class="en">
          INTELLIGENT DRAINAGE MANAGEMENT SYSTEM OF NANJING GULOU HOSPITAL
        </div>
      </div>

      <div class="container">
        <img class="bg" src="@/assets/login/circle-bg.png" alt="" />
        <div class="card" v-if="!isLogin">
          <div class="title-box">
            <img
              class="welcome-bg"
              src="@/assets/login/welcome-bg.png"
              alt=""
            />
            <div class="title">欢迎登录</div>
          </div>

          <div class="content flex-column-center">
            <div class="field">
              <img src="@/assets/login/input-bg.png" alt="" />
              <img class="icon" src="@/assets/login/human-icon.png" alt="" />
              <div class="divider"></div>
              <input
                type="text"
                placeholder="用户名"
                v-model="name"
                @keydown.enter="login"
              />
            </div>
            <div class="field">
              <img src="@/assets/login/input-bg.png" alt="" />
              <img class="icon" src="@/assets/login/password-icon.png" alt="" />
              <div class="divider"></div>
              <input
                type="password"
                placeholder="密码"
                v-model="password"
                @keydown.enter="login"
              />
            </div>

            <img class="line" src="@/assets/login/line.png" alt="" />
            <div class="button-box center">
              <img
                class="button"
                src="@/assets/login/button-bg.png"
                @click="login"
                alt=""
              />
            </div>
          </div>
        </div>
        <div v-else>
          <img src="@/assets/login/underline.png" class="underline" alt="" />
          <div class="cockpit flex-column-center" @click="$router.push('/')">
            <img src="@/assets/login/cockpit.png" alt="" />
            <span>驾驶舱</span>
          </div>
          <div class="manage flex-column-center" @click="$router.push('/')">
            <img src="@/assets/login/manage.png" alt="" />
            <span>运营管理平台</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login";
import encrypt from "@/utils/encrypt";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      name: "",
      password: "",
      isLogin: false,
    };
  },

  methods: {
    ...mapMutations("auth", ["setUser"]),
    login() {
      if (!this.name || !this.password) {
        this.$message.error("请填写用户名或密码");
        return;
      }

      const hide = this.$message.loading("登陆中...");

      const params = {
        password: encrypt.encrypt(this.password),
      };

      params.name = this.name;

      login(params)
        .then(this.afterLogin)
        .finally(() => {
          hide();
        });
    },

    afterLogin(res) {
      if (res && res.token && res.user) {
        window.localStorage.setItem("token", res.token);
        console.log(res.user);
        this.isLogin = true;

        this.setUser(res.user);

        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-box {
  height: 100vh;
  width: 100vw;
  // padding-top: 0.5em;
  box-sizing: border-box;
  background-color: #000;
  background: url("../../assets/login/denglu-bg.png") no-repeat;

  background-position: center;
  background-size: cover;
}
.login {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  position: relative;
  text-align: center;
  .zh {
    font-size: 5vh;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 3px;
    text-shadow: 0px 2px 4px rgba(2, 27, 35, 0.5);
  }
  .en {
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
    line-height: 28px;
    letter-spacing: 6px;
    white-space: nowrap;
  }
  margin-bottom: 10vh;
}
.container {
  position: relative;
  width: 45vw;
  span {
    font-size: 4vh;
    font-family: YouSheBiaoTiHei;
    color: #e9fef9;
    line-height: 4vh;
  }
  .underline {
    position: absolute;
    width: 150%;
    top: 20vh;
    left: -10vw;
  }
  .cockpit {
    position: absolute;
    top: 14vh;
    left: 8vw;
    cursor: pointer;
  }
  .manage {
    position: absolute;
    top: 20vh;
    left: 30vw;
    cursor: pointer;
  }
  .bg {
    position: absolute;
    width: 200%;
    top: -55vh;
    left: -20vw;
  }
}
.card {
  width: 45vw;
  position: relative;

  .title-box {
    width: 100%;
    height: 16vh;
    position: relative;
    margin-bottom: 3vh;
  }
  .welcome-bg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .title {
    font-size: 3vh;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: #ffffff;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -40%);

    background-position: center;
    background-size: cover;
    margin-bottom: 4vh;
  }

  .content {
    width: 100%;
  }
}

.field {
  display: flex;
  align-content: center;
  width: 60%;
  margin-bottom: 1em;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .icon {
    position: absolute;
    height: 2em;
    width: 2em;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
  }
  input {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
  }
}

.line {
  margin-bottom: -12vh;
}

.button {
  width: 80%;
  cursor: pointer;
}
</style>
